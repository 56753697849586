import { useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

import { DragDropContext, Draggable, DraggableLocation, Droppable } from 'react-beautiful-dnd';
import { Controller, useFormContext } from 'react-hook-form';

import { LinkKnowledgeDto } from '../../../../../../../generateAxios';
import { AppContentWrapper } from '../../../../../AppContentWrapper/AppContentWrapper.tsx';
import { KnowledgeLinkEditModal } from '../../../../../Modals/KnowledgeLinkEditModal/KnowledgeLinkEditModal.tsx';
import { KnowledgeFormValues } from '../../../../KnowledgeEditor.tsx';

import { AddedLink } from './AddedLink/AddedLink.tsx';
import classes from './KnowledgeEditorLinks.module.css';

export const KnowledgeEditorLinks = () => {
  const [changeLinkModal, setChangeLinkModal] = useState<{
    isOpen: boolean;
    data: LinkKnowledgeDto | null;
    index: number | null;
  }>({ data: null, isOpen: false, index: null });

  const { control, setValue, getValues, trigger } = useFormContext<KnowledgeFormValues>();

  const clickChangeLink = (index: number) => {
    setChangeLinkModal({ data: getValues('links')?.[index] ?? null, isOpen: true, index });
    console.log(getValues('links')?.[index]);
  };

  const confirmChangeLink = (data: LinkKnowledgeDto, index: number) => {
    const values = getValues('links');
    const newValues = values?.map((el, i) => (i === index ? data : el));
    setValue('links', newValues);
  };

  return (
    <AppContentWrapper width={'wide'} className={classes.container}>
      <Text size={'l'} weight={'medium'}>
        Ссылки
      </Text>

      <div className={classes.addedLinks}>
        <Controller
          name={'links'}
          control={control}
          rules={{
            // required: { value: true, message: 'Поле обязательно для заполнения' },
            validate: {
              hasLink: (value, formValues) =>
                (value && value.length > 0) ||
                (formValues.newLink?.link && formValues.newLink.link.trim().length > 0) ||
                'Поле обязательно для заполнения',
            },
          }}
          render={({ field }) => {
            const deleteLink = (index: number) => {
              field.onChange(field.value?.filter((_, i) => i !== index));
            };

            const onDragEnd = ({
              destination,
              source,
            }: {
              destination?: DraggableLocation | null;
              source: DraggableLocation;
            }) => {
              if (!destination) return;
              if (!field.value) return;
              const newItems = [...field.value];
              const [reorderedItem] = newItems.splice(source.index, 1);
              newItems.splice(destination.index, 0, reorderedItem);
              setValue('links', newItems);
            };
            if (!field.value || field.value.length === 0) return <></>;
            return field.value.length === 1 ? (
              <AddedLink
                link={field.value[0].link}
                title={field.value[0].description}
                index={0}
                onChangeLink={(index) => {
                  clickChangeLink(index);
                }}
                deleteLink={deleteLink}
              />
            ) : (
              <div className={classes.imgList}>
                <Text view={'secondary'}>Порядок отображения</Text>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId={'images'}>
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={cnMixFlex({
                          flex: 'flex',
                          direction: 'column',
                          gap: 'm',
                          align: 'stretch',
                        })}
                      >
                        {field.value?.map((el, index) => (
                          <Draggable key={index} draggableId={index.toString()} index={index}>
                            {(provided) => (
                              <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <AddedLink
                                  link={el.link}
                                  title={el.description}
                                  index={index}
                                  onChangeLink={(index) => {
                                    clickChangeLink(index);
                                  }}
                                  deleteLink={deleteLink}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            );
          }}
        />
      </div>
      <div className={classes.newLink}>
        <Controller
          name={'newLink'}
          control={control}
          rules={{
            validate: {
              validateLink: (value) => {
                if (!value?.link || value?.link?.toLowerCase()?.match(/^https?:\/\//)) {
                  return true;
                } else {
                  return 'Ссылка должна начинаться с http:// или https://';
                }
              },
            },
          }}
          render={({ field, formState: { errors } }) => {
            const changeText = (value: string | null) => {
              const oldValue = field.value;
              field.onChange({ ...oldValue, description: value });
            };
            const changeLink = async (value: string | null) => {
              const oldValue = field.value;
              field.onChange({ ...oldValue, link: value });
              await trigger('links');
            };
            const addLink = async () => {
              if (field.value?.link) {
                await trigger('links');
                const triggerResult = await trigger('newLink');
                if (!triggerResult) {
                  return;
                }
                const link = field.value.link;

                const description = field.value.description?.trim()
                  ? field.value.description.trim()
                  : link.length > 50
                    ? link.slice(0, 50) + '...'
                    : link;

                const oldLinks = getValues('links');
                const newLinks = oldLinks
                  ? [...oldLinks, { link, description }]
                  : [{ link, description }];
                setValue('links', newLinks);
                field.onChange(undefined);
              }
            };
            return (
              <>
                <TextField
                  label={'Название'}
                  width={'full'}
                  // type={'textarea'}
                  // rows={5}
                  value={field.value?.description}
                  onChange={({ value }) => changeText(value)}
                />
                <TextField
                  label={'Ссылка'}
                  inputRef={field.ref}
                  width={'full'}
                  value={field.value?.link}
                  onChange={({ value }) => changeLink(value)}
                  status={errors.links || errors.newLink ? 'alert' : undefined}
                  caption={
                    errors.links?.message || errors.newLink?.message
                      ? `${errors.links?.message ? errors.links?.message : ''}${errors.links?.message && errors.newLink?.message ? '\n' : ''}${errors.newLink?.message ? errors.newLink?.message : ''}`
                      : undefined
                  }
                  required
                />
                <Button
                  view={'ghost'}
                  size={'s'}
                  iconLeft={IconAdd}
                  label={'Добавить ещё'}
                  onClick={addLink}
                  className={cnMixSpace({ pR: 'm', mT: '2xs' })}
                  disabled={!field.value?.link}
                />
              </>
            );
          }}
        />
      </div>
      <KnowledgeLinkEditModal
        data={changeLinkModal.data}
        isOpen={changeLinkModal.isOpen}
        index={changeLinkModal.index}
        confirm={confirmChangeLink}
        close={() => setChangeLinkModal((prev) => ({ ...prev, isOpen: false }))}
        afterClose={() => setChangeLinkModal((prev) => ({ ...prev, data: null }))}
      />
    </AppContentWrapper>
  );
};
