import { useEffect } from 'react';

import { Button } from '@consta/uikit/Button';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

import { Controller, useForm } from 'react-hook-form';

import { LinkKnowledgeDto } from '../../../../generateAxios';
import { knowledgeConfig } from '../../../configs/knowledgeConfig.ts';

import { AppModal } from '../../AppModal/AppModal.tsx';
import { Divider } from '../../Divider/Divider.tsx';

import classes from './KnowledgeLinkEditModal.module.css';

export const KnowledgeLinkEditModal = ({
  isOpen,
  data,
  index,
  confirm,
  close,
  afterClose,
}: {
  isOpen: boolean;
  data: LinkKnowledgeDto | null;
  index: number | null;
  close: () => void;
  afterClose: () => void;
  confirm: (link: LinkKnowledgeDto, index: number) => void;
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<LinkKnowledgeDto>({
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('link', data?.link ?? '');
    setValue('description', data?.description ?? '');
    trigger('link');
    trigger('description');
  }, [data]);

  const submit = (data: LinkKnowledgeDto) => {
    if (!data.link || index === null) return;
    const description = data.description?.trim()
      ? data.description.trim()
      : data.link.length > 50
        ? data.link.slice(0, 50) + '...'
        : data.link;
    confirm({ link: data.link, description }, index);
    close();
  };

  return (
    <AppModal isOpen={isOpen} close={close} afterClose={afterClose} className={classes.modal}>
      <Text
        size={'xl'}
        weight={'medium'}
        className={cnMixSpace({ pL: '2xl', pR: '4xl', pT: 'l', pB: 'l' })}
      >
        Редактирование ссылки
      </Text>
      <Divider />
      <div className={classes.content}>
        <Controller
          name={'description'}
          control={control}
          rules={{
            maxLength: {
              value: knowledgeConfig.knowledgeLinkDescriptionMaxLength,
              message: `Максимальное количество символов ${knowledgeConfig.knowledgeLinkDescriptionMaxLength}`,
            },
          }}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              label={'Название'}
              placeholder={'Введите название'}
              inputRef={ref}
              width={'full'}
              value={field.value ?? ''}
              onChange={({ value }) => field.onChange(value ?? '')}
              status={errors.description ? 'alert' : undefined}
              caption={errors.description?.message}
            />
          )}
        />
        <Controller
          name={'link'}
          control={control}
          rules={{
            required: 'Поле обязательно для заполнения',
            validate: {
              validateLink: (value) => {
                if (value && value?.toLowerCase()?.match(/^https?:\/\//)) {
                  return true;
                } else {
                  return 'Ссылка должна начинаться с http:// или https://';
                }
              },
            },
          }}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              label={'Ссылка'}
              placeholder={'Введите ссылку'}
              required
              inputRef={ref}
              width={'full'}
              value={field.value ?? ''}
              onChange={({ value }) => field.onChange(value ?? '')}
              status={errors.link ? 'alert' : undefined}
              caption={errors.link?.message}
            />
          )}
        />
      </div>

      <Divider />
      <div className={classes.buttonContainer}>
        <Button label={'Отменить'} view={'ghost'} className={classes.button} onClick={close} />
        <Button
          label={'Сохранить'}
          className={classes.button}
          onClick={handleSubmit((data) => submit(data))}
        />
      </div>
    </AppModal>
  );
};
